<template>
  <main class="action-page">
    <div class="action-page__skinxs-icon icon-universkin-logo"></div>
    <div v-if="shouldShowMessage">
      <div class="text--level-1">{{ level1Text }}</div>
      <div class="text--level-2">{{ level2Text }}</div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex';

import { confirmRedeem, rejectRedeem } from '@/api';

import { getDecodedId } from '@/utils';

import rootTypes from '@/store/types';
import { REQUEST_ERRORS } from '@/constants';

const REDEEM_ACTIONS = {
  CONFIRM: 'confirm',
  REJECT: 'reject'
};

export default {
  name: 'LoyaltyProgramRedeemAction',
  props: {
    hashedOperationId: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      shouldShowMessage: false,
      hasRequestError: false
    };
  },
  computed: {
    isConfirmAction() {
      return this.action === REDEEM_ACTIONS.CONFIRM;
    },
    level1Text() {
      if (this.hasRequestError) {
        return 'Something went wrong.';
      }

      return this.isConfirmAction ? 'Thanks for your validation.' : 'You have rejected redemption.';
    },
    level2Text() {
      if (this.hasRequestError) {
        return 'If you need more infomation, please contact Universkin team for details.';
      }

      return this.isConfirmAction
        ? 'A Universkin staff will now contact the participant to organize the redeem process.'
        : '';
    }
  },
  async mounted() {
    const redeemRequest = this.isConfirmAction ? confirmRedeem : rejectRedeem;
    const [operationId] = getDecodedId(this.hashedOperationId);

    this.setLoading(true);

    const { status } = await redeemRequest(operationId);

    this.setLoading(false);

    if (status === REQUEST_ERRORS.BAD_REQUEST) {
      this.hasRequestError = true;
    }

    this.shouldShowMessage = true;
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING
    })
  },
  metaInfo: {
    title: 'Redeem action',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/action-page/action-page-shared';
</style>
